import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { handleDates } from './format-time';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

// Set Accept-Language header based on stored language or browser language
axiosInstance.interceptors.request.use((config) => {
  config.headers['Accept-Language'] =
    localStorage.getItem('i18nextLng') || navigator.language || 'en';
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Ошибка сервера')
);

axiosInstance.interceptors.response.use((originalResponse) => {
  if (originalResponse.data) {
    handleDates(originalResponse.data);
  }
  return originalResponse;
});

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: '/api/auth/login/',
    refreshToken: '/api/token/refresh/',
  },
  users: {
    me: '/api/users/me',
    list: '/api/users/',
    get: (uid: string) => `/api/users/${uid}/`,
    search: '/api/users/search/',
    update: (uid: string) => `/api/users/${uid}/`,
    create: '/api/users/',
  },
  groups: {
    list: '/api/groups/',
    get: (groupName: string) => `/api/groups/${groupName}/`,
    lite: '/api/groups/lite/',
    update: (groupName: string) => `/api/groups/${groupName}/`,
    create: '/api/groups/',
  },
  permissions: {
    list: '/api/permissions/',
    lite: '/api/permissions/lite/',
  },
  communicationRequests: {
    list: '/api/communication-requests/',
    get: (uid: string) => `/api/communication-requests/${uid}`,
    update: (uid: string) => `/api/communication-requests/${uid}`,
    writeNote: (uid: string) => `/api/communication-requests/${uid}/write-note`,
  },
  news: {
    list: '/api/news/',
    get: (uid: string) => `/api/news/${uid}/`,
    update: (uid: string) => `/api/news/${uid}/`,
    create: '/api/news/',
    uploadBanner: '/api/news/upload-banner',
  },
  linkIn: {
    list: '/api/linkedin-posts',
    get: (uid: string) => `/api/linkedin-posts/${uid}/`,
    update: (uid: string) => `/api/linkedin-posts/${uid}/`,
    create: '/api/linkedin-posts/',
  },
  regions: {
    list: '/api/operating-regions',
    get: (uid: string) => `/api/operating-regions/${uid}/`,
    update: (uid: string) => `/api/operating-regions/${uid}/`,
    create: '/api/operating-regions/',
  },
  regionCropPrices: {
    list: '/api/region-prices/',
    get: (uid: string) => `/api/region-prices/${uid}/`,
    update: (uid: string) => `/api/region-prices/${uid}/`,
    delete: (uid: string) => `/api/region-prices/${uid}/`,
    create: '/api/region-prices/',
  },
  organisations: {
    list: '/api/organisations/',
    listTags: '/api/organisations/tags/',
    get: (uid: string) => `/api/organisations/${uid}/`,
    update: (uid: string) => `/api/organisations/${uid}/`,
    create: '/api/organisations/',
    bankDetails: {
      list: (organisationUid: string) => `/api/organisations/${organisationUid}/bank-details/`,
      update: (organisationUid: string, uid: string) =>
        `/api/organisations/${organisationUid}/update-bank-details/`,
      create: (organisationUid: string) =>
        `/api/organisations/${organisationUid}/create-bank-details/`,
    },
  },
  crops: {
    list: '/api/crops/',
    get: (uid: string) => `/api/crops/${uid}/`,
    update: (uid: string) => `/api/crops/${uid}/`,
    create: '/api/crops/',
  },
  cropServices: {
    list: '/api/crop-services/',
    get: (uid: string) => `/api/crop-services/${uid}/`,
  },
  terminals: {
    list: '/api/terminals/',
    get: (uid: string) => `/api/terminals/${uid}/`,
    update: (uid: string) => `/api/terminals/${uid}/`,
    create: '/api/terminals/',
  },
  currencies: {
    list: '/api/currencies/',
    get: (id: number) => `/api/id/${id}/`,
  },
  units: {
    list: '/api/units/',
  },
  exchangeRates: {
    list: '/api/exchange-rates/',
    get: (uid: string) => `/api/exchange-rates/${uid}/`,
  },
  geo: {
    countries: {
      list: '/api/geo/countries',
      get: (id: number) => `/api/geo/countries/${id}`,
    },
    regions: {
      list: `/api/geo/regions/`,
      get: (id: number) => `/api/geo/regions/${id}`,
    },
    cities: {
      list: `/api/geo/cities/`,
      get: (id: number) => `/api/geo/cities/${id}`,
    },
  },
  purchaseContracts: {
    list: '/api/purchase-contracts/',
    get: (uid: string) => `/api/purchase-contracts/${uid}/`,
    downloadDocx: (uid: string) => `/api/purchase-contracts/${uid}/download-docx/`,
    update: (uid: string) => `/api/purchase-contracts/${uid}/`,
    create: '/api/purchase-contracts/',
    generateNumber: '/api/purchase-contracts/generate-number/',
  },
  qualitiesCriteria: {
    list: '/api/quality-criteria/',
    get: (uid: string) => `/api/quality-criteria/${uid}`,
    update: (uid: string) => `/api/quality-criteria/${uid}`,
    create: '/api/quality-criteria/',
  },
  salesContracts: {
    list: '/api/sales-contracts/',
    get: (uid: string) => `/api/sales-contracts/${uid}`,
    update: (uid: string) => `/api/sales-contracts/${uid}/`,
    create: '/api/sales-contracts/',
  },
  transshipments: {
    list: '/api/transshipments/',
    get: (uid: string) => `/api/transshipments/${uid}`,
    update: (uid: string) => `/api/transshipments/${uid}/`,
    create: '/api/transshipments/',
  },
  fractions: {
    list: '/api/residue-fractions/',
    get: (uid: string) => `/api/residue-fractions/${uid}/`,
    update: (uid: string) => `/api/residue-fractions/${uid}/`,
    create: '/api/residue-fractions/',
    transship: (uid: string) => `/api/residue-fractions/${uid}/transship/`,
  },
  shipments: {
    list: '/api/shipments/',
    get: (uid: string) => `/api/shipments/${uid}`,
    update: (uid: string) => `/api/shipments/${uid}`,
    create: '/api/shipments/',
  },
  truckShipments: {
    list: '/api/truck-shipments/',
    get: (uid: string) => `/api/truck-shipments/${uid}/`,
    update: (uid: string) => `/api/truck-shipments/${uid}/`,
    create: '/api/truck-shipments/',
    bulkCreate: '/api/truck-shipments/bulk-create/',
  },
  bargesShipments: {
    list: '/api/barge-shipments/',
    get: (uid: string) => `/api/barge-shipments/${uid}/`,
    update: (uid: string) => `/api/barge-shipments/${uid}/`,
    create: '/api/barge-shipments/',
  },
  wagonsShipments: {
    list: '/api/wagon-shipments/',
    get: (uid: string) => `/api/wagon-shipments/${uid}/`,
    update: (uid: string) => `/api/wagon-shipments/${uid}/`,
    create: '/api/wagon-shipments/',
  },
  payments: {
    purchase: {
      list: '/api/payments/purchase/',
      get: (uid: string) => `/api/payments/purchase/${uid}`,
      update: (uid: string) => `/api/payments/purchase/${uid}`,
      create: '/api/payments/purchase/',
    },
  },
};
